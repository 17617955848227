<template>
	<div class="container">
		<Nav :curIndex="0"></Nav>
		<div class="account_right">
			<div class="page-title">Account Summary</div>
			<div class="user-box">
				<el-avatar class="avatar" icon="el-icon-user-solid"></el-avatar>
				<div class="right">
					<div class="account">Welcome, {{account}}</div>
					<div class="link-list">
						<div class="link-item" @click="to('/MyOrders')">My Orders</div>
						<div class="link-item" @click="to('/MyProfile')">Complete Profile</div>
					</div>
				</div>
			</div>
			<div class="balance-box">
				<div class="title">Available Balance</div>
				<div class="balance">
					<div class="value">{{$currencySymbol}}{{balance}}</div>
					<div class="deposit-btn" @click="to('/deposit')">Go To Deposit</div>
				</div>
			</div>
			<div class="my-order">
				<div class="title">My Orders</div>
				<el-table :data="list" style="width: 100%;">
					<el-table-column prop="order_no" label="Order Number">
					</el-table-column>
				    <el-table-column prop="total_price" label="Total Amount">
				    </el-table-column>
					<el-table-column label="Status">
						<template slot-scope="scope">
                            <el-tag :type="curStatus(scope.row.status).tag" size="small" effect="plain">{{curStatus(scope.row.status).name}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="created_at" label="Creation Time">
					</el-table-column>
				    <el-table-column label="Action" fixed="right">
				        <template slot-scope="scope">
				            <div class="detail-btn" @click="toDetail(scope.row.id)">Detail</div>
				        </template>
				    </el-table-column>
				</el-table>
                <div class="pagination-box">
                    <el-pagination
                    class="pagination-pc" 
                    :hide-on-single-page="true"
                    :current-page="currentPage"
                    :pager-count="11"
                    :page-size="pageSize"
                    :total="total" 
                    @current-change="handleCurrentChange" 
                    background 
                    layout="prev, pager, next">
                    </el-pagination>
                    <el-pagination
                    class="pagination-h5" 
                    :hide-on-single-page="true"
                    :current-page="currentPage"
                    :pager-count="5"
                    :page-size="pageSize"
                    :total="total" 
                    @current-change="handleCurrentChange" 
                    background 
                    layout="prev, pager, next">
                    </el-pagination>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
                account: '-',
				balance: 0,
                list: [],
                pageSize: 0,
                total: 0,
                currentPage: 1
            }
        },
        created() {
            this.getUserInfo()
            this.getOrderList()
        },
        computed: {
            curStatus() {
                return (status) => {
                    let obj = {
                        tag: '',
                        name: ''
                    }
                    switch(status) {
                        case 0:
                            obj = {
                                tag: 'warning',
                                name: 'Unpaid'
                            }
                            break
                        case 1:
                            obj = {
                                tag: 'info',
                                name: 'Wait for delivery'
                            }
                            break
                        case 2:
                            obj = {
                                tag: '',
                                name: 'To be received'
                            }
                            break
                        case 3:
                            obj = {
                                tag: 'success',
                                name: 'Completed'
                            }
                            break
                    }
                    
                    return obj
                }
            }
        },
        methods: {
            getUserInfo() {
                this.$api.user_info().then(res => {
                    this.account = res.data.phone
					this.balance = Number(res.data.balance)
                })
            },
            
            getOrderList() {
                this.$api.orderList({
                    page: this.currentPage
                },true).then(res => {
                    this.list = res.data.data.map(item => {
                        item.total_price = parseFloat(item.total_price)
                        return item
                    })
                    this.pageSize = res.data.per_page
                    this.total = res.data.total
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getOrderList()
            },
            
            to(path) {
            	this.$router.push({
            	    path
            	})
            },
			
			toDetail(id) {
				this.$router.push({
				    path: '/OrderDetail',
                    query: {
                        id
                    }
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
        		.page-title {
        			width: 100%;
        			margin-bottom: 2rem;
        			font-size: 2rem;
        			color: #333333;
        			word-wrap: break-word;
        		}
        		
        		.user-box {
        			display: flex;
        			align-items: center;
        			width: 50rem;
        			height: 13rem;
        			box-sizing: border-box;
        			padding: 0 3rem;
        			margin-bottom: 3rem;
        			border: 1px solid #e9e9e9;
        			border-radius: 2px;
        			
        			.avatar {
        				min-width: 7.2rem;
        				max-width: 7.2rem;
        				height: 7.2rem;
        				line-height: 7.2rem;
        				margin-right: 3rem;
        			}
        			
        			/deep/ .el-avatar--icon {
        				font-size: 3.6rem;
        			}
        			
        			.right {
        				width: 100%;
        				
        				.account {
        					width: 100%;
        					font-size: 1.7rem;
        					font-weight: bold;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.link-list {
        					display: flex;
        					flex-direction: column;
        					width: 100%;
        					
        					.link-item {
        						max-width: 100%;
        						margin-top: 1rem;
        						font-size: 1.4rem;
        						color: #21a4ea;
        						word-wrap: break-word;
        						text-decoration: underline;
        						cursor: pointer;
        					}
        				}
        			}
        		}
				
				.balance-box {
					width: 100%;
					margin-bottom: 3rem;
					
					.title {
						width: 100%;
						margin-bottom: 1.5rem;
						font-size: 2rem;
						color: #333333;
						word-wrap: break-word;
					}
					
					.balance {
						display: flex;
						align-items: center;
						width: 100%;
						
						.value {
							max-width: 100%;
							font-size: 3.5rem;
							font-weight: bold;
							color: #333333;
							word-break: break-all;
						}
						
						.deposit-btn {
							min-width: 12rem;
							max-width: 12rem;
							height: 4rem;
							line-height: 4rem;
							box-sizing: border-box;
							margin-left: 3rem;
							border-radius: 4px;
							font-size: 1.4rem;
							color: #FFFFFF;
							text-align: center;
							white-space: nowrap;
							background-color: #333333;
							cursor: pointer;
						}
					}
				}
        		
        		.my-order {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 1.5rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.detail-btn {
        				max-width: 100%;
        				height: 3rem;
        				line-height: 3rem;
        				box-sizing: border-box;
        				padding: 0 1rem;
        				border-radius: 2px;
        				font-size: 1.4rem;
        				color: #FFFFFF;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #21a4ea;
        				cursor: pointer;
        			}
                    
                    .pagination-box {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        padding: 3rem 0 0;
                        
                        .pagination-h5 {
                            display: none;
                        }
                    }
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.page-title {
        			width: 100%;
        			margin-bottom: 2rem;
        			font-size: 1.8rem;
        			color: #333333;
        			word-wrap: break-word;
        		}
        		
        		.user-box {
        			display: flex;
        			align-items: center;
        			width: 100%;
        			height: 13rem;
        			box-sizing: border-box;
        			padding: 0 1.5rem;
        			margin-bottom: 2.5rem;
        			border: 1px solid #e9e9e9;
        			border-radius: 2px;
        			
        			.avatar {
        				min-width: 6rem;
        				max-width: 6rem;
        				height: 6rem;
        				line-height: 6rem;
        				margin-right: 1.5rem;
        			}
        			
        			/deep/ .el-avatar--icon {
        				font-size: 3rem;
        			}
        			
        			.right {
        				width: 100%;
        				
        				.account {
        					width: 100%;
        					font-size: 1.6rem;
        					font-weight: bold;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.link-list {
        					display: flex;
        					flex-direction: column;
        					width: 100%;
        					
        					.link-item {
        						max-width: 100%;
        						margin-top: 1rem;
        						font-size: 1.4rem;
        						color: #21a4ea;
        						word-wrap: break-word;
        						text-decoration: underline;
        						cursor: pointer;
        					}
        				}
        			}
        		}
				
				.balance-box {
					width: 100%;
					margin-bottom: 2.5rem;
					
					.title {
						width: 100%;
						margin-bottom: 1.2rem;
						font-size: 1.8rem;
						color: #333333;
						word-wrap: break-word;
					}
					
					.balance {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						
						.value {
							max-width: 100%;
							font-size: 3rem;
							font-weight: bold;
							color: #333333;
							word-break: break-all;
						}
						
						.deposit-btn {
							min-width: 11.2rem;
							max-width: 11.2rem;
							height: 3.6rem;
							line-height: 3.6rem;
							box-sizing: border-box;
							margin-left: 2rem;
							border-radius: 4px;
							font-size: 1.4rem;
							color: #FFFFFF;
							text-align: center;
							white-space: nowrap;
							background-color: #333333;
							cursor: pointer;
						}
					}
				}
        		
        		.my-order {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 1.2rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.detail-btn {
        				max-width: 100%;
        				height: 2.6rem;
        				line-height: 2.6rem;
        				box-sizing: border-box;
        				padding: 0 .8rem;
        				border-radius: 2px;
        				font-size: 1.2rem;
        				color: #FFFFFF;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #21a4ea;
        				cursor: pointer;
        			}
                    
                    .pagination-box {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        padding: 2.5rem 0 0;
                        
                        .pagination-pc {
                            display: none;
                        }
                    }
        		}
        	}
        }
    }
</style>